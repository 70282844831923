<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="custom-width">
            <!-- Brand logo-->
            <div class="d-flex justify-content-between">
              <b-link class="brand-logo-update">
                <DefaultLogo />
              </b-link>
              <ul class="lang-sign-up">
                <locale />
              </ul>
            </div>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold bt-40">
              {{ $t("login.ForgotPass") }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("login.Forgottitle1") }} <br />
              {{ $t("login.Forgottitle2") }} <br />
              {{ $t("login.Forgottitle3") }}
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="forgotPasswordValidation"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- email -->
                <b-form-group :label="$t('login.Email')">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-input-group
                      :class="{
                        'is-invalid': errors.length > 0,
                        'is-valid': errors.length === 0 && userEmail
                      }"
                      class="input-group-merge"
                    >
                      <b-input-group-prepend
                        is-text
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid': errors.length === 0 && userEmail
                        }"
                      >
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        :class="
                          errors.length == 0 && userEmail ? 'is-valid' : null
                        "
                        id="email"
                        :style="userEmail ? 'text-transform: lowercase' : ''"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        name="email"
                        :placeholder="$t('Forgot_Email_Place')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="recaptcha">
                  <re-captcha
                    :valid="valid"
                    :reset="reset"
                    @state="update($event)"
                    @rerender="handleRerender"
                  />
                  <small v-if="!valid || !captchaChecked" class="text-danger">{{
                    hasErrs
                  }}</small>
                </div>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading || !valid"
                >
                  {{ $t("login.SendConfirmation") }}
                </b-button>
              </b-form>
            </validation-observer>
            <b-card-text class="text-center back-to-login">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" />
                {{ $t("login.BackLogin") }}
              </b-link>
            </b-card-text>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthService from "@/libs/api/auth-service";
import ReCaptcha from "../../layouts/components/ReCaptcha.vue";
import LoginImage from "@/assets/images/pages/login-v2.svg";
import Locale from "@/layouts/components/Locale.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,

    BInputGroupPrepend,
    ReCaptcha,
    LoginImage,
    Locale
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      valid: false,
      reset: false,
      hasErrs: "",
      loading: false,
      captchaChecked: false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    update(value) {
      this.captchaChecked = true;
      this.valid = value;
    },
    handleRerender() {
      // Toggle the value to trigger a re-render of the child component
      this.reset = !this.reset;
    },
    async validationForm() {
      this.loading = true;
      if (!this.valid || !this.captchaChecked) {
        this.loading = false;
        return (this.hasErrs = this.$t("login.CaptchaError"));
      }
      try {
        let response = await new AuthService().forgotPasswordRequest({
          email: this.userEmail.toLowerCase(),
          recaptcha_token: this.valid
        });
        if (response && response.data && response.data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Reset Password success title"),
              text: this.$t("Reset Password success"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.$router.push(`login`);
        } else if (response && response.error && response.error.message) {
          this.captchaChecked = false;
          this.handleRerender();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        } else {
          this.captchaChecked = false;
          this.handleRerender();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$router.push("/").catch(() => {});
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.loading = false;
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.fill-primary {
  fill: var(--primary);
}
</style>
