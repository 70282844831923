// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: "password",
      confirmPasswordFieldType: "password",
      newPasswordFieldType: "password",
      oldPasswordFieldType: "password",
    };
  },
  methods: {
    togglePasswordVisibility(key) {
      if (key === "old_pass") {
        this.oldPasswordFieldType =
          this.oldPasswordFieldType === "password" ? "text" : "password";
      } else if (key === "new_pass") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
      } else if (key === "confirm_pass") {
        this.confirmPasswordFieldType =
          this.confirmPasswordFieldType === "password" ? "text" : "password";
      } else {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      }
    },
  },
};

export const _ = null;
