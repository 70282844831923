<template>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <b-spinner v-show="loading" label="Spinning"></b-spinner>
    <vue-recaptcha
      :key="recaptchaKey"
      v-show="!loading"
      sitekey="6Lcr_VYnAAAAANicbvQtYNfC_9QLfWtVpXwzdV6M"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @error="handleError"
      @expired="handleCaptchaExpired"
    ></vue-recaptcha>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { BSpinner } from "bootstrap-vue";
export default {
  components: { VueRecaptcha, BSpinner },
  props: {
    reset: Boolean
  },
  watch: {
    reset(newValue) {
      this.resetRecaptcha();
    }
  },
  data() {
    return {
      loading: true,
      recaptchaKey: 1
    };
  },
  methods: {
    handleError() {
      this.$emit("state", null);
    },
    handleSuccess(token) {
      this.$emit("state", token);
    },
    handleCaptchaExpired() {
      this.$emit("state", null);
    },
    resetRecaptcha() {
      // Update the key property to reset the reCAPTCHA instance
      this.recaptchaKey += 1;
    }
  },
  mounted() {
    this.resetRecaptcha();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
};
</script>
