var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-flex align-items-start auth-bg px-2 p-lg-5-update",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"custom-width"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-link',{staticClass:"brand-logo-update"},[_c('DefaultLogo')],1),_c('ul',{staticClass:"lang-sign-up"},[_c('locale')],1)],1),_c('b-card-title',{staticClass:"font-weight-bold bt-40",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t("login.ForgotPass"))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("login.Forgottitle1"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("login.Forgottitle2"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("login.Forgottitle3"))+" ")]),_c('validation-observer',{ref:"forgotPasswordValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('login.Email')}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && _vm.userEmail
                    }},[_c('b-input-group-prepend',{class:{
                        'is-invalid': errors.length > 0,
                        'is-valid': errors.length === 0 && _vm.userEmail
                      },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{class:errors.length == 0 && _vm.userEmail ? 'is-valid' : null,style:(_vm.userEmail ? 'text-transform: lowercase' : ''),attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":_vm.$t('Forgot_Email_Place')},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1),_c('div',{staticClass:"recaptcha"},[_c('re-captcha',{attrs:{"valid":_vm.valid,"reset":_vm.reset},on:{"state":function($event){return _vm.update($event)},"rerender":_vm.handleRerender}}),(!_vm.valid || !_vm.captchaChecked)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.hasErrs))]):_vm._e()],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading || !_vm.valid}},[_vm._v(" "+_vm._s(_vm.$t("login.SendConfirmation"))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center back-to-login"},[_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("login.BackLogin"))+" ")],1)],1)],1)])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 pd-0",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"},[_c('div',{staticClass:"login-bg"},[_c('LoginImage',{staticClass:"object-login-img"})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }